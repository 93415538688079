import React from "react";
import './style.css';

const Footer = () => (
  <div className="footer" title="Visitenkarte laden">
    <a href='./tinttec_GmbH.vcf'>
        <p>tinttec&nbsp;GmbH | Bertastrasse&nbsp;85 | 8003&nbsp;Zürich | +41&nbsp;(0)44&nbsp;774&nbsp;4040 | contact@tinttec.ch | www.tinttec.ch</p>
      </a>
  </div>
);

export default Footer;