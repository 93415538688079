import Footer from './Footer'
import logo from './tinttec_logo.jpg';
import './App.css';
import app from 'firebase/app';
import 'firebase/analytics';

app.initializeApp({
  //other config
  apiKey: "AIzaSyA2vR88oM6xdUk6GrJCRwxH4SBvbbaJ8zc",
  authDomain: "tinttec-ch.firebaseapp.com",
  projectId: "tinttec-ch",
  storageBucket: "tinttec-ch.appspot.com",
  messagingSenderId: "895567941436",
  appId: "1:895567941436:web:aa2207f97748c9cb21d839",
  measurementId : "G-KJ914WH92K"
})

//put inside your constructor
app.analytics()

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <a href='mai&#108;to&#58;c&#37;6Fn&#116;%61ct&#64;ti&#110;&#116;tec%&#50;E%63h'>
          <img src={logo} className="App-logo" alt="logo" />
        </a>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
